
body.down header.clone {
	top:0;
	opacity: 1;
	transition:0.4s all cubic-bezier(0.19, 1, 0.22, 1); 
}

body header.clone {
	top:-150px;
	opacity: 0;
	transition:1.5s all cubic-bezier(0.19, 1, 0.22, 1); 
}

header {
	box-shadow: 0 4px 20px -2px #f4f4f4;
	transition:0.4s all cubic-bezier(0.19, 1, 0.22, 1); 

	.property-manager{
		color: #666!important;

		&:hover, &:focus{
			color: #fff!important;
		}
	}

	&.clone {
		position:fixed;
		top:-150px;
		left: 0;
		opacity: 0;
		right: 0;
		z-index:9999;
		
		box-shadow: none;
		border-bottom: 1px solid #e4e4e4;
	}

	.top-row {
		padding: 20px 30px 20px;
		position: relative;
		background: #fff;
		@media(max-width: 991px){
			padding: 20px 20px 20px;
		}
		@media(max-width: 767px){
			padding: 20px 15px;
		}
		@media(max-width: 575px){
			
		}

		.logo {
			margin-right: 30px;
			@media(max-width: 1199px){
				margin-right: 20px;
				flex-basis: 180px;
				max-width: 180px;
			}
			@media(max-width: 991px){
				margin-right: 20px;
				flex-basis: 180px;
				max-width: 180px;
			}
			@media(max-width: 575px){
				margin-right: 20px;
				flex-basis: 160px;
				max-width: 160px;
			}
			
			
		}

		.menu{

			z-index: 1050;;
			

			.menu-toggle{

				cursor: pointer;
			
				.line{
					height: 7px;
					background: #636363;
					width: 30px;
					display: block;
					border-radius: 10px;
					margin: 3px 0;
					transition: 0.2s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
				}
			}

			&.open{
				ul{
					transform: scaleY(1);
					opacity: 1;
					visibility: visible;
					transition: 0.2s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
				}
				.menu-toggle{
					.line:nth-child(1){
						transform: rotate(45deg);
						transform-origin: 30% 100%;
					}
					.line:nth-child(2){
						transform: rotate(-45deg);
						transform-origin: 33% 5%;
					}
				}
			}
			
			ul {
				margin: 0;
				@media(max-width: 991px){
					white-space: nowrap;
				}
				@media(max-width: 767px){
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					width: 100%;
					background: #f1f1f1;
					text-align: center;
					padding: 15px 0; 
					z-index: 100;
					transform: scaleY(0);
					opacity: 0;
					visibility: hidden;
					transform-origin: top;
					transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
					
					
				}
				li{
					@media(max-width: 991px){
						margin: 0;	
						
					}
					@media(max-width: 767px){
						display: block;
						width: 100%;
					}
					a {
						font-size: 23px;
						display: block;
						padding: 0 20px;
						color: #6e6d6c;
						font-weight: 500;
		
						&:hover,
						&:focus {
							text-decoration: none;
							color: $orange;
						}
						@media(max-width: 1199px){
							font-size: 20px;
							padding: 0 10px;
						}

						@media(max-width: 767px){
							font-size: 18px;
							padding: 8px 20px;
						}
						
					}
				}
	
			}
		}

		.right{
			padding-right: 120px;
			@media(max-width: 1199px){
				padding-right: 100px;
			}
			@media(max-width: 767px){
				padding-right: 90px;
			}
			@media(max-width: 575px){
				padding-right: 60px;
			}
		}

		.search{
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			z-index: 1051;
			transition:0.4s all cubic-bezier(0.19, 1, 0.22, 1); 

			flex-basis: 120px;
			max-width: 120px;
			width: 120px;


			@media(max-width: 1199px){
				flex-basis: 100px;
				max-width: 100px;
				width: 100px;
			}
			@media(max-width: 767px){
				flex-basis: 80px;
				max-width: 80px;
				width: 80px;
			}
			@media(max-width: 575px){
				flex-basis: 60px;
				max-width: 60px;
				width: 60px;
			}
			
			display: flex;
			align-items: stretch;
			justify-content: center;
			

			background: #f4f4f4;

			&.search-open{
				flex-basis: 100%;
				max-width: 100%;
				width: 100%;

				.search-results{
					display: none;
					top: 108px;
					position: absolute;
					height: 300px;
					@media(max-width: 1199px){
						top: 84px;
					}
					@media(max-width: 767px){
						top: 75px;
					}
					@media(max-width: 575px){
						top: 71px;
					}

					.no-results{
						display: block;
						padding: 0 20px;
					}
				}
				.search-field{
					flex-basis: calc(100% - 100px);
					max-width: calc(100% - 100px);
					@media(max-width: 1199px){
						flex-basis: calc(100% - 120px);
						max-width: calc(100% - 120px);
					}
					@media(max-width: 767px){
						flex-basis: calc(100% - 80px);
						max-width: calc(100% - 80px);
					}
					@media(max-width: 575px){
						flex-basis: calc(100% - 60px);
						max-width: calc(100% - 60px);
					}
				}
			}

			.search-toggle{
				flex-basis: 120px;
				max-width: 120px;
				width: 120px;
				
				@media(max-width: 1199px){
					flex-basis: 100px;
					max-width: 100px;
					width: 100px;
				}
				@media(max-width: 767px){
					flex-basis: 80px;
					max-width: 80px;
					width: 80px;

					svg{
						width: 35px;
						height: 35px;
					}
				}
				@media(max-width: 575px){
					flex-basis: 50px;
					max-width: 50px;
					width: 50px;

					svg{
						width: 25px;
						height: 25px;
					}
				}
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				&:hover, &:focus{
					svg{
						path{
							stroke: $orange;
						}
					}
				}
			}

			.search-field{
				flex-basis: 0;
				max-width: 0;
				width: 0;
				position: static;
				height: 100%;
				display: flex;
				align-items: stretch;

				.form-control{
					height: auto;
					border: 0;
					background: none;
					font-size: 20px;
					padding: 0;
				}
			
			}

			
		}

		



		

		.social ul {
			margin: 0;
			@media(max-width: 991px){
				margin-left: 20px;
			}
		}
	}
}
