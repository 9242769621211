@import "/public_html/css/animate.css";
@import "/public_html/css/star-rating-svg.css";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900'); 
@import url("https://use.typekit.net/nmz4fqa.css");
@font-face {
    font-family: 'Big John';
    src: url('fonts/BigJohn.eot');
    src: url('fonts/BigJohn.eot?#iefix') format('embedded-opentype'),
        url('fonts/BigJohn.woff2') format('woff2'),
        url('fonts/BigJohn.woff') format('woff'),
        url('fonts/BigJohn.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@import "colours";
@import "header";
@import "home";
@import "page";
@import "footer";
@import url('https://fonts.cdnfonts.com/css/open-sauce-one');
body {
	color: $text;
	font-weight: 400;

	font-family: futura-pt, sans-serif;

	font-size: 18px;
	@media(max-width: 991px){
		font-size: 17px;
	}
	@media(max-width: 767px){
		font-size: 16px;
	}
    line-height: 1.3;

	img {
		max-width: 100%;
	}

	.modal-backdrop{
		z-index: 10000;
	}

	a {
		color: $orange;
		transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
		&:hover, &:focus{
			color: $blue;
			text-decoration: none;
		}
	}

	.form-control {
		border-radius: 0px;
		border: 3px solid #e1e1e1;
		box-shadow: none;
		outline: none;

		&:focus,
		&:hover {
			border-color: #e1e1e1;
			box-shadow: none;
			outline: none;
		}
	}

	.custom.radio{

		label{
			position: relative;
			padding-left: 40px;
			display: block;
			user-select: none;
			cursor: pointer;
			margin-bottom: 15px;
			font-size: 22px;
			@media(max-width: 991px){
				font-size: 20px;
			}
			@media(max-width: 767px){
				font-size: 17px;
				padding-left: 30px;
			}
			input{
				position: absolute;
				opacity: 0;
				color: #343434;
				cursor: pointer;
				&:checked ~ .checkmark {
					background-color: $blue;
					&:after {
						display: block;
					}
				}
			}
	
			&:hover{
				input ~ .checkmark {
					background-color: #e4e4e4;
				}
				input{
					&:checked ~ .checkmark {
						background-color: $blue;
					}
				}
			}
	
	
			.checkmark {
				position: absolute;
				transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
				top: calc(50% - 11px);
				left: 0;
				height: 22px;
				width: 22px;
				@media(max-width: 767px){
					height: 18px;
					width: 18px;
					top: calc(50% - 9px);
				}
				background-color: #f2f2f2;
				border-radius: 0%;
				
			  }
		}
		
		
	}

	.search-results{
		top: 100%;
		left: 0;
		position: absolute;
		padding: 0;
		background: #f9f9f9;
		width: 100%;
		display: none;
		z-index: 1000;
		max-height: 400px;
		overflow-y: auto;
		box-shadow: 0 10px 20px -6px rgba(0,0,0,0.4);
		h5{
			font-size: 22px;
			font-weight: 500;
			color: #fff;
			padding-bottom: 5px;
			margin-bottom: 10px;
			background: $blue;
			padding: 10px 20px;
			@media(max-width: 767px){
				padding: 5px 20px;
			}
		}

		.list{
			margin-bottom: 20px;
			span{
				padding: 0 20px;
			
			}
		}

		a{
			display: block;
			margin-bottom: 5px;
			padding: 0 20px;
			@media(max-width: 767px){
				margin-bottom: 7px;
			}
			color: #666;
			&:hover, &:focus{
				color: $orange;
			}
		}
	}

	.section {
		padding: 50px 0;
		@media(max-width: 991px){
			padding: 40px 0;
		}
		@media(max-width: 767px){
			padding: 30px 0;
		}
		@media(max-width: 575px){
			padding: 20px 0;
		}

	}

	.font-orange {
		color: $orange;
	}

	.font-blue {
		color: $blue;
	}

	.button {
		padding: 5px 20px;
		font-weight: 500;
		min-width: 160px;
		text-align: center;

		&:hover,
		&:focus {
			text-decoration: none;
		}

		&.full {
			&.orange {
				background: $orange;
				color: #fff;
				background: linear-gradient(to bottom, $orange 50%, $blue 50%);
				overflow: hidden;
				background-repeat: repeat;
				background-size: 101% 200%;

				&:hover,
				&:focus {
					background-position: 0% 100% !important;
				}
			}

			&.blue {
				background: $blue;
				color: #fff;
				background: linear-gradient(to bottom, $blue 50%, $orange 50%);
				overflow: hidden;
				background-repeat: repeat;
				background-size: 101% 200%;

				&:hover,
				&:focus {
					background-position: 0% 100% !important;
				}
			}

			
		}

		&.bordered {
			&.orange {
				border: 4px solid $orange;
				color: $orange;
				background: #fff;
				background: linear-gradient(to bottom, #fff 50%, $orange 50%);
				overflow: hidden;
				background-repeat: repeat;
				background-size: 101% 200%;
				font-weight: 500;

				&:hover,
				&:focus {
					background-position: 0% 100% !important;
					color: #fff;
				}

			}

			&.blue {
				border: 4px solid $blue;
				color: $blue;
				background: #fff;
				background: linear-gradient(to bottom, #fff 50%, $blue 50%);
				overflow: hidden;
				background-repeat: repeat;
				background-size: 100% 200%;

				&:hover,
				&:focus {
					background-position: 0% 100% !important;
					color: #fff;
				}

			}

			&.green {
				border: 4px solid #95d475;
				color: #95d475;
				background: #fff;
				background: linear-gradient(to bottom, #fff 50%, #95d475 50%);
				overflow: hidden;
				background-repeat: repeat;
				background-size: 100% 200%;

				&:hover,
				&:focus {
					background-position: 0% 100% !important;
					color: #fff;
				}

			}
		}
	}

	.page-header{
		position: relative;
		padding: 40px 0;
		background: #fafafa;
		margin-bottom: 35px;
		h2, br{
			display: none;
		}

		.text{
			h1{
				display: none;
			}
		}
		@media(max-width: 991px){
			padding: 30px 0;
		}
		@media(max-width:767px){
			padding-top: 0;
			text-align: center;
			margin-bottom: 30px;
		}
		@media(max-width:767px){
			
			margin-bottom: 20px;
		}
		.graphic{
			position: absolute;
			background-size: cover;
			background-position: left center, 50% 50%;
			right: 0;
			top:0;
			height: 100%;
			width: 48%;
			max-width: 767px;
			
			opacity: 0;
			img{
				height: 100%;
			}
			@media(max-width: 767px){
				position: relative;
				height: 35vw;
				width: 100%;
				background-size: 0 0, cover;
			}
		}

		h1, .h1, .page-header-title {
			font-size: 40px;
			color: #888888;
			display: block;
			margin: 0;
			margin-bottom: 30px;
			line-height: 1;
			@media(max-width: 1199px){
				margin-bottom: 15px;
				font-size: 33px; 
			}
			@media(max-width: 991px){
				margin-bottom: 20px;
				font-size: 25px;
			}
			@media(max-width: 767px){
				margin-bottom: 15px;
				margin-top: 20px;
				font-size: 22px;
			}
			@media(max-width: 575px){
				font-size: 19px;
			}
			span{
				font-size: 70px;
				color: $blue;
				display: block;
				
				margin-top: -12px;
				font-family: 'Big John';
				@media(max-width: 1199px){
					
					font-size: 55px;
					margin-top: -8px;
				}
				@media(max-width: 991px){
					margin-top: -5px;
					font-size: 40px;
				}
				@media(max-width: 767px){
					font-size: 30px;
					margin-top: 0px;
				}
				@media(max-width: 575px){
					font-size: 25px;
				}
			}
		}

		.text{
			color: #666666;
			font-size: 18px;
			line-height: 1.3;
			opacity: 0;
			font-weight: 400;
			@media(max-width: 1199px){
					
				font-size: 17px;
			}
			@media(max-width: 991px){
					
				font-size: 16px;
			}
			
			@media(max-width: 767px){
				font-size: 15px;
				
			}
			@media(max-width: 575px){
				line-height: 1.2;
				
			}
			p{
				margin-bottom: 0;
			}
		}
	}
}

.cities-section{
	@media(max-width: 767px){
		padding-bottom: 30px;
	}
	
	h3{
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 40px;
		@media(max-width: 991px){
			font-size: 35px;
		}
		@media(max-width: 767px){
			font-size: 30px;
			margin-bottom: 30px;
		}
		@media(max-width: 575px){
			font-size: 25px;
			margin-bottom: 20px;
		}
	}

	.city{
		min-height: 250px;
		opacity: 0;
		background-size: cover;
		background-position: 50% 50%;
		margin-bottom: 30px;
		position: relative;
		display: block;

		&.full-opacity{
			opacity: 1;
		}
		@media(max-width: 767px){
			min-height: 200px;
		}
		@media(max-width: 575px){
			min-height: 50vw;
		}
		.label{
			display: inline-block;
			padding: 5px 30px;
			font-size: 23px;
			font-weight: 500;
			position: absolute;
			bottom: 20px;
			color: #fff;
			letter-spacing: 1px;
			left:0;
			line-height: 1;
			transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);

			
		}

		&:hover, &:focus{
			.label{
				background-position: -100% 0% !important;
			}
		}
	}
	
	.row{
		> div:nth-child(odd){
			.label{
				background: linear-gradient(to right, rgba(253, 181, 21, 0.5) 50%, $orange 50%);
				overflow: hidden;
				background-repeat: repeat; 
				background-size: 201% 100%;
				
			}
		}
		> div:nth-child(even){
			.label{
				background: linear-gradient(to right, rgba(105,204,224,0.5) 50%, $blue 50%);
				overflow: hidden;
				background-repeat: repeat; 
				background-size: 201% 100%;
				
			}
		}
	}

	.button{
		padding: 10px 20px;
		text-align: center;
		font-size: 23px
	}

	

}

.city-description{
	

	@media(max-width: 767px) {
		text-align: center;
		
		margin-bottom: 30px;
	}

	.city-more{
		color: $blue;
		font-weight: 500;
		letter-spacing: 1px;
		font-size: 19px;
		cursor: pointer;
		display: inline-block;
		&:hover{
			color: $orange;
		}
	}
}



#markerContent{

	width: 300px;
	padding: 6px 0;

	&.uni{
		width: 145px;
		padding: 0;
		text-align: center;
	}

	
	.row{
		max-width: 100%;
		margin-left: -5px;
		margin-right: -5px;
		> div{
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	a{
		&.image{
			background-size: cover;
			flex-basis: 100%;
			max-width: 100%;
			background-position: 50% 50%;
			border: 2px solid #e4e4e4;
		}
		display: block;
		img{
			max-width: 100%;
		}

		&.bordered{
			border-width: 2px;
		}
	}

	h5{
		font-size: 15px;
		font-weight: 500;
		color: #343434;
		margin-bottom: 5px;
	}

	address{
		margin-bottom: 10px;
	}
}


.pagination{

	justify-content: flex-end;
	
	border-radius: 0;
	li{
		border-radius: 0;
	}
	.page-item{
		border-radius: 0!important;
		.page-link{
			padding: 6px 15px;
			font-size: 16px;
			border-radius: 0!important;
			background: none;
			
			border: 0px solid #ddd;
			
			font-weight: 500;
			letter-spacing: 1px;
			color: #777;
			@media(max-width: 991px) {
				padding: 6px 15px;
				
			}
			@media(max-width: 767px) {
				font-size: 15px;letter-spacing: 0px;
				
			}
		}
		&.active{
			.page-link{
				color: #fff;
				background: $blue;
				border: 0px solid #ddd;
			
			}
			&:hover{
				.page-link{
					background: $blue;
				}
			}
		}
		&.disabled{
			.page-link{
			color: #aaa;
			}
		}

		&:hover, &:focus{
			.page-link{
				background: #eee;
				box-shadow: none;
			}
		}
	}

}

.rs, .review-rs,.nearby-rs{
	white-space: nowrap;
}

.modal{
	border-radius: 0;
	z-index: 10001;
	.modal-dialog{
		border-radius: 0;
		.modal-content{
			border-radius: 0;

			&.viewing{
				border: 10px solid #ccc;
			}
			&.enquire{
				border: 5px solid $blue;
			}
			
			.modal-header{
				flex-wrap: wrap;
				padding: 0;
				border-bottom: 0px;
				.modal-logo{
					padding: 10px;
					flex-basis: 100%;
					max-width: 100%;
					border-bottom: 1px solid #ddd;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: space-between;
					img{
						max-width: 150px;
					}

					.close{
						margin-right: 0;
						padding: 0 10px;
						span{
							font-size: 40px;
						}
					}
				}
				h5{
					padding: 10px 1rem 5px;
					margin: 0;
					line-height: 1.2;
					text-align: center;
					flex-basis: 100%;
					max-width: 100%;
					font-size: 25px;
					@media(max-width: 575px) {
						font-size: 22px;
					}
				}
				.modal-header-text{
					padding: 0 40px;
					font-weight: 300;
					text-align: center;
					margin-bottom: 1rem;
					font-size: 18px;
					line-height: 1.2;
					@media(max-width: 575px) {
						font-size: 16px;
					}
					
				}
			}
			.modal-body{
				padding-top: 0;
				label{
					margin-bottom: 5px;
				}

				.input-group-text{
					background: #e1e1e1;
					border: 0;
					border-radius: 0;
					margin-left: -3px;
				}

				.error{
					font-style: italic;
					font-weight: 400;
					font-size: 16px;
					color: darkred;
				}

				.message{
					flex-basis: 100%;
					max-width: 100%;
					.alert{
						width: 100%;
						border-radius: 0;
						margin: 10px 0 0;
						padding: 7px 10px;
						display: block;
						text-align: center;
						border-width: 4px;
					}
				}
			}
			.modal-footer{
				button{
					flex-basis: 50%;
					max-width: 50%;
					cursor: pointer;
					@media(max-width: 430px) {
						flex-basis: 100%;
						max-width: 100%;
						margin: 0;
						&:last-child{
							margin-bottom: 10px;
						}
						
					}
				}
				@media(max-width: 430px) {
					flex-wrap: wrap;
					flex-direction: column-reverse;
					
				}
				
			}
		}
	}
}

.jq-stars {
	display: inline-block;
  }
  
  .jq-rating-label {
	font-size: 22px;
	display: inline-block;
	position: relative;
	vertical-align: top;
	font-family: helvetica, arial, verdana;
  }
  
  .jq-star {
	width: 100px;
	height: 100px;
	display: inline-flex; 
	justify-content: center;
	align-items: center;
	cursor: pointer;
  }
  
  .jq-star-svg {
	padding-left: 3px;
	width: 100%;
	height: 100% ;
  }
  
  .jq-star:hover .fs-star-svg path {
  }
  
  .jq-star-svg path {
	/* stroke: #000; */
	stroke-linejoin: round;
  }
  
  /* un-used */
  .jq-shadow {
	-webkit-filter: drop-shadow( -2px -2px 2px #888 );
	filter: drop-shadow( -2px -2px 2px #888 );
  }