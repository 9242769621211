#home {
	.search-section {
		background-size: cover;
		background-position: 50% 50%;
		background: #e4e4e4;
		position: relative;
		.home-slider{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.owl-stage-outer{
				height: 100%;
				.owl-stage{
					height: inherit;
					.owl-item{
						height: inherit;
						.item{
							height: inherit;
							background-size: cover;
							background-position: 50% 50%;
						}
					}
				}
			}
		}
		>.container{
			z-index: 20;
			position: relative;
		}
		.inner {
			padding: 160px 0px;
			@media(max-width: 1199px){
				padding: 110px 0px;
			}
			@media(max-width: 991px){
				padding: 80px 0px;
			}
			@media(max-width: 767px){
				padding: 40px 0px 60px;
				position: relative;
			}
			@media(max-width: 575px){
				padding: 40px 0px 60px;
			}

			.search-bar {
				padding: 5px 0 5px 0;
				opacity: 0;
				background: #fff;
				position: relative;
				box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6);
				@media(max-width: 767px){
					flex-basis: 80%;
					max-width: 80%;
					padding: 0;
					
					box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6);
				}
				@media(max-width: 575px){
					flex-basis: 95%;
					max-width: 95%;
					padding: 0;
					box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6);
				}

				.search-icon {
					margin-right: 20px;
					margin-left: 20px;
					@media(max-width: 767px){
						margin-right: 20px;
						margin-left: 20px;
					}
					@media(max-width: 575px){
						margin-right: 15px;
						margin-left: 15px;
						img{
							width: 30px;
						}
					}
				} 

				.form-control { 
					height: 50px;
					font-size: 25px;
					font-weight: 100;
					font-weight: 300;
					margin-right: 20px;
					padding: 10px 0;
					border: 0px;
					@media(max-width: 767px){
						font-size: 18px;
						height: 50px;
					}
				}

				::placeholder {
					color: $text;
					opacity: 1;
				}

				.button {
					font-size: 25px;
					line-height: 1;
					letter-spacing: 1px;
					@media(max-width: 767px){
						position: absolute;
						margin: 0 auto;
						margin-top: 10px;
						top: 100%;
						left: calc(50% - 80px);
						box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
					}
				}
			}
		}
	}

	.notification-section{
		background: $orange;
		color: #fff;
		text-align: center;
		p{
			margin: 10px 0;
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 1px;
		}

		.button{
			margin: 10px 20px;
		}

	}

	.standfor-section {
		h3 {
			font-weight: 500;
			font-size: 46px;
			margin-bottom: 40px;
			@media(max-width: 991px){
				font-size: 35px;
			}
			@media(max-width: 767px){
				font-size: 30px;
				margin-bottom: 30px;
			}
			@media(max-width: 575px){
				font-size: 25px;
				margin-bottom: 20px;
			}
		}

		.icon {
			width: 140px;
			height: 140px;
			border-radius: 50%;
			background: $blue;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px;
			margin: 0 auto;
			margin-bottom: 20px;
			@media(max-width: 991px){
				width: 120px;
				height: 120px;
				margin-bottom: 10px;
			}
			@media(max-width: 767px){
				width: 90px;
				height: 90px;
				margin-bottom: 10px;
			}
			
		}

		.text {
			padding: 0 10px;

			h4 {
				margin-bottom: 5px;
				font-size: 25px;
				@media(max-width: 767px){
					font-size: 22px;
				}
			}

			.p {
				font-size: 16px;
				font-weight: 100;
				font-weight: 300;
				@media(max-width: 767px){
					font-size: 15px;
					line-height: 1.2;
				}
			}
			@media(max-width: 991px){
				
				padding: 0px;
			}
			@media(max-width: 767px){
				
				margin-bottom: 20px;
			}
		}

	}

	.rate-review-section{
		background: linear-gradient(135deg, $blue 0%,$orange 100%); 
		color: #fff;
		font-weight: 400;
		@media(max-width: 767px){
			text-align: center;
		}
		h3{
			font-size: 42px;
			font-weight: 500;
			@media(max-width: 991px){
				font-size: 35px;
			}
			@media(max-width: 767px){
				font-size: 30px;
				margin-bottom: 30px;
			}
			@media(max-width: 575px){
				font-size: 25px;
				margin-bottom: 20px;
			}
			
		}

		.text{
			font-size: 22px;
			position: relative;
			padding-top: 20px;
			@media(max-width: 767px){
				font-size: 20px;
				padding-top: 0;
			}
			@media(max-width: 575px){
				font-size: 18px;
			}
			&:before{
				content: '';
				height: 4px;
				width: 70%;
				background: #fff;
				position: absolute;
				top: 0;
				left: 0;
				@media(max-width: 767px){
					display: none;
				}

			}
		}

		.search-bar {
			padding: 5px 5px 5px 15px;
			background: #fff;
			margin-bottom: 20px;
			box-shadow: 0px 0px 26px rgba(0,0,0,0.2); 
			z-index: 1000!important;
			.search-icon {
				width: 25px;
			} 

			.form-control { 
				font-size: 20px;
				font-weight: 100;
				font-weight: 300;
				margin-right: 20px;
				border: 0px;
			}

			::placeholder {
				color: $text;
				opacity: 1;
			}

			
		}

	}
	
}
