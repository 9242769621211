.page-content {
	padding: 40px 0;
	background: #fafafa;
	box-shadow: inset 0 40px 60px -40px rgba(0, 0, 0, 0.07);

	&.ld{
		.loading{
	
			text-align: center;
			position: absolute;
			opacity: 1!important;
			visibility: visible!important;
			background: rgba(255,255,255,0.5);
			top: -10px;
			left: -10px;
			height: calc(100% + 20px);
			width: calc(100% + 20px);
			z-index: 10;
			.loader,
		.loader:before,
		.loader:after {
		  background: #69cce0;
		  -webkit-animation: load1 1s infinite ease-in-out;
		  animation: load1 1s infinite ease-in-out;
		  width: 1em;
		  height: 4em;
		}
		.loader {
		  color: #69cce0;
		  text-indent: -9999em;
		  margin: 88px auto;
		  position: relative;
		  font-size: 11px;
		  -webkit-transform: translateZ(0);
		  -ms-transform: translateZ(0);
		  transform: translateZ(0);
		  -webkit-animation-delay: -0.16s;
		  animation-delay: -0.16s;
		}
		.loader:before,
		.loader:after {
		  position: absolute;
		  top: 0;
		  content: '';
		}
		.loader:before {
		  left: -1.5em;
		  -webkit-animation-delay: -0.32s;
		  animation-delay: -0.32s;
		}
		.loader:after {
		  left: 1.5em;
		}
		@-webkit-keyframes load1 {
		  0%,
		  80%,
		  100% {
			box-shadow: 0 0;
			height: 4em;
		  }
		  40% {
			box-shadow: 0 -2em;
			height: 5em;
		  }
		}
		@keyframes load1 {
		  0%,
		  80%,
		  100% {
			box-shadow: 0 0;
			height: 4em;
		  }
		  40% {
			box-shadow: 0 -2em;
			height: 5em;
		  }
		}
		
		}

		#results{
				filter: blur(3px);
					
				transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
				
		}
	}

	@media(max-width: 767px) {
		padding: 30px 0;
	}

	@media(max-width: 575px) {
		padding: 20px 0;
	}

	&.white {
		background: #fff;
		box-shadow: none;
		padding-top: 5px;
		padding-bottom: 0;

		@media(max-width: 575px) {
			padding: 0;
		}
	}

	h1 {
		font-weight: bold;
		font-family: futura-pt-bold, sans-serif;
		color: $orange;
		line-height: 1.2;
		font-size: 50px;
		margin-top: 0;
		margin-bottom: 20px;

		@media(max-width: 991px) {
			font-size: 40px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
		}
	}

	.text {
		font-weight: 300;
		font-size: 18px;

		@media(max-width: 991px) {
			font-size: 17px;
		}

		@media(max-width: 767px) {
			font-size: 16px;
		}

		&.top-text {
			color: #666;
			margin-bottom: 40px;

			@media(max-width: 767px) {
				margin-bottom: 10px;

			}

			>div {
				br {
					display: none;
				}
			}
		}

		#accordion {
			border-radius: 0;
			border: 0;
			margin-bottom: 80px;

			@media(max-width: 991px) {
				margin-bottom: 60px;
			}

			@media(max-width: 767px) {
				margin-bottom: 40px;
			}

			.card {
				border-radius: 0;
				border: 0;

				.card-header {
					padding: 0;
					border-radius: 0;
					border: 0;
					border-top: 1px solid #fafafa;

					.btn {
						display: block;
						padding: 10px 25px;
						padding-right: 50px;
						font-size: 22px;
						white-space: normal;
						line-height: 1.2;
						font-weight: 500;
						color: #fff;
						background: $dblue;
						width: 100%;
						text-align: left;
						text-decoration: none;
						border-radius: 0;
						position: relative;
						border: 0;

						@media(max-width: 991px) {
							font-size: 20px;
						}

						@media(max-width: 767px) {
							font-size: 18px;
							padding: 10px 20px;
						}

						@media(max-width: 575px) {
							padding: 10px 15px;
						}

						&:before {
							position: absolute;
							width: 50px;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							content: '\f077';
							font-family: FontAwesome;
							font-size: 26px;
							right: 0;
							top: 0;

							@media(max-width: 991px) {
								font-size: 24px;
							}

							@media(max-width: 767px) {
								font-size: 22px;
							}

						}

						&.collapsed {

							background: $blue;

							&:before {
								transform: rotate(180deg);
								transform-origin: center;
							}

						}

					}

				}

				.card-body {
					background: #efefef;
					padding: 25px;
					font-weight: 300;
					font-size: 18px;

					@media(max-width: 991px) {
						font-size: 17px;
					}

					@media(max-width: 767px) {
						font-size: 16px;
						padding: 20px;
					}

					@media(max-width: 575px) {
						font-size: 16px;
						padding: 20px 15px;
					}

					b,
					strong {
						font-weight: 500;
					}

					em{
						font-weight: 400;
					}
				}
			}
		}

		

		#map-search {

			.distance-slider{
				label{
					font-weight: 100;
					color: #343434;
					font-size: 20px;
					
				}
				span{
					
					font-weight: 500;
					
				}
			}

			margin-top: 40px;

			@media(max-width: 991px) {
				margin-top: 30px;
			}

			@media(max-width: 767px) {
				margin-top: 0;

				iframe {
					display: none;
				}

			}

			.map {
				height: 100%;
				position: relative;

				#search-overlay {
					position: absolute;
					top: 0;
					width: 100%;
					background: rgba(105, 204, 224, 0.6);
					height: 100%;
					z-index: 9;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						color: #fff;
					}
				}

				#google-map {
					height: 100%;
					@media(max-width: 767px) {
						height: 200px;
	
					}
				}
			}


			

			.filters {
				background: #fff;
				padding: 20px;
				box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

				@media(max-width: 767px) {
					margin-bottom: 10px;

				}

				

				.heading {
					font-weight: 500;
					font-size: 26px;
					display: block;
					color: #343434;
					margin-top: 0;
					margin-bottom: 15px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					@media(max-width: 991px) {
						font-size: 22px;
					}

					@media(max-width: 767px) {
						font-size: 18px;
					}

					.results-size{
						font-weight: 500;
						font-size: 15px;
						color: #666;
					}
				}

				.search-bar {
					padding: 5px 5px 5px 15px;
					background: #f5f5f5;
					margin-bottom: 20px;
					position: relative; 
					.search-icon {
						width: 25px;
					}

					.form-control {
						font-size: 20px;
						font-weight: 100;
						font-weight: 300;
						margin-right: 20px;
						background: #f5f5f5;
						border: 0px;

						@media(max-width: 991px) {
							font-size: 18px;
						}

						@media(max-width: 767px) {
							font-size: 17px;
						}
					}

					::placeholder {
						color: $text;
						opacity: 1;
					}
				}

				.search-results{
					top: 100%;
					left: 0;
					position: absolute;
					padding: 0;
					background: #f9f9f9;
					width: 100%;
					display: none;
					z-index: 1000;
					max-height: 400px;
					overflow-y: auto;
					box-shadow: 0 10px 20px -6px rgba(0,0,0,0.4);
					h5{
						font-size: 20px;
						font-weight: 500;
						color: #fff;
						padding-bottom: 5px;
						margin-bottom: 10px;
						background: $blue;
						padding: 10px 10px;
					}
			
					.list{
						margin-bottom: 20px;
					}
			
					a{
						display: block;
						margin-bottom: 5px;
						padding: 0 20px;
						color: #666;
						&:hover, &:focus{
							color: $orange;
						}
					}

					.no-results{
						display: block;
						padding: 0 10px;
					}
				}
			}

		}

		.view-more {
			padding: 20px 20px;
			text-align: center;
			font-size: 23px;

			@media(max-width: 991px) {
				font-size: 20px;
				padding: 15px 20px;
			}

			@media(max-width: 767px) {
				font-size: 18px;
			}
		}

		.search-result{
			position: relative;


			.loading{
				visibility: hidden;
				opacity: 0;
			}
			.no-results{
				display: block;
				padding: 0 10px;
			}

			
		}

		

		#results {
			position: relative;

			
			

			
			
			  
			

			

			.room {
				margin-bottom: 40px;

				@media(max-width: 991px) {
					margin-bottom: 30px;
				}

				@media(max-width: 767px) {
					margin-bottom: 20px;
				}

				.image {
					background-size: cover;
					background-position: 50% 50%;

					@media(max-width: 991px) {
						height: 250px;
					}

					@media(max-width: 575px) {
						height: 45vw;
					}
				}

				.room-info {
					.top {
						background: #fff;
						padding: 40px 45px;

						@media(max-width: 991px) {
							padding: 25px 30px;
						}

						@media(max-width: 767px) {
							padding: 20px 30px;
						}

						@media(max-width: 575px) {
							padding: 20px 20px;
						}

						h4 {
							@media(max-width: 767px) {
								margin-bottom: 0;
							}

							.reviews_count{
								font-size: 14px;
							}

							a {
								font-size: 31px;
								color: $orange;

								@media(max-width: 1199px) {
									margin-bottom: 0;
									font-size: 25px;
								}

								@media(max-width: 991px) {
									margin-bottom: 0;
									font-size: 23px;
								}

								@media(max-width: 767px) {
									font-size: 20px;
								}

								&:hover,
								&:focus {
									color: darken($orange, 5%);
									text-decoration: none;
								}
							}
						}


						.distance {
							position: relative;
							padding-left: 30px;
							margin-left: 30px;
							display: block;
							font-size: 15px;
							color: #666;
							font-weight: 500;

							&:before {
								position: absolute;
								content: '';
								height: 15px;
								width: 15px;
								border-radius: 50%;
								background: $orange;
								top: 1px;
								left: 0;
							}

							@media(max-width: 991px) {
								margin-left: 0;
								padding-left: 0;
								margin-bottom: 0;

								&:before {
									display: none;
								}
							}

							@media(max-width: 767px) {

								margin-bottom: 10px;

							}

						}

						.description {
							color: #000000;
							font-size: 18px;
							line-height: 1.3;

							@media(max-width: 1199px) {
								font-size: 17px;
							}

							@media(max-width: 991px) {
								font-size: 16px;
							}

							@media(max-width: 767px) {
								font-size: 14px;
							}


							a {
								color: #9c9c9c;
								font-size: 18px;
								font-weight: 500;

								@media(max-width: 1199px) {
									font-size: 17px;
								}

								@media(max-width: 991px) {
									font-size: 16px;
								}

								@media(max-width: 767px) {
									font-size: 14px;
								}

								&:hover,
								&:focus {
									color: $orange;
									text-decoration: none;
								}
							}

							.company-logos{ 

								.company-logo{

									display: inline-block;
									margin-left: 10px;
									border: 4px solid #e4e4e4;
									padding: 3px;
									img{
										display: block; 
										margin: 0 auto;
										max-height: 40px;
									}

								}
							}
						}
					}

					.buttons {
						@media(max-width: 575px) {
							flex-wrap: wrap;
						}

						>span {
							flex-basis: 33.333333%;
							max-width: 33.333333%;
							background: $blue;
							padding: 20px 20px;
							font-size: 28px;
							font-weight: 500;
							color: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							line-height: 1;

							@media(max-width: 1199px) {
								font-size: 24px;
							}

							@media(max-width: 991px) {
								font-size: 22px;
							}

							@media(max-width: 767px) {
								padding: 15px 10px;
								font-size: 18px;
							}

							&.price {
								@media(max-width: 575px) {
									flex-basis: 50%;
									max-width: 50%;
								}
								line-height: 1;
								display: flex;
								align-items: flex-end;
								justify-content: center;
								white-space: nowrap;

								.per {
									font-size: 18px;
									padding: 0;
									line-height: 1;
									margin-left: 5px;
									margin-right: 5px;
									@media(max-width: 575px) {
										font-size: 14px;
									}
								}
							}

							&.availability {
								background: $orange;
								border-left: 3px solid #fff;
								border-right: 3px solid #fff;

								@media(max-width: 575px) {
									flex-basis: 50%;
									max-width: 50%;
									border-right: 0px solid #fff;
								}
							}

							&.rating {
								ul {
									margin: 0;

									li {
										margin-right: 0;

										i {
											font-size: 25px;
											color: #fbfd2e;

											@media(max-width: 767px) {
												font-size: 20px;

											}
										}

									}
								}


								@media(max-width: 575px) {
									font-size: 20px;
									flex-basis: 100%;
									max-width: 100%;
									border-top: 3px solid #fff;
								}
							}


						}
					}


				}
			}
		}
	}

	hr {
		margin-top: 40px;
		margin-bottom: 40px;
		border-top: 1px solid $blue;

		@media(max-width: 767px) {
			margin-top: 30px;
			margin-bottom: 30px;
		}

		@media(max-width: 575px) {
			margin-top: 20px;
			margin-bottom: 20px;
		}

	}

	.extras {

		.extra-page{
			opacity: 0;
		}

		.icon {
			width: 140px;
			height: 140px;
			border-radius: 50%;
			background: $blue;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px;
			margin: 0 auto;

			@media(max-width: 991px) {
				width: 120px;
				height: 120px;
				margin-bottom: 10px;
			}

			@media(max-width: 767px) {
				width: 90px;
				height: 90px;
				margin-bottom: 10px;
			}
		}

		.text {
			padding: 0 10px;
			margin-bottom: 40px;

			h3 {
				margin-bottom: 15px;
				margin-top: 15px;
				font-size: 25px;
				color: #666;
				font-weight: 500;

				@media(max-width: 767px) {
					font-size: 22px;
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}

			p {
				font-size: 16px;
				font-weight: 300;

				@media(max-width: 767px) {
					font-size: 15px;
					line-height: 1.2;
				}
			}

			a {
				display: inline-block;
				margin: 0 auto;
			}

		}
	}

	&.hall {
		background: #fff;

		@media(max-width: 575px) {
			padding-top: 0;
			>.container{
				padding-left: 0;
				padding-right: 0;
				overflow-x: hidden;
				>.row{
					margin-left: 0;
					margin-right: 0;
					>div{
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}

		#videos{
			iframe{
				width: 100%; 
				height: 300px; 
				margin-bottom: 30px;
				@media (max-width: 1199px){ 
						height: 250px;
				}
				@media (max-width: 991px){ 
						height: 220px;
				}
			}

		}


		.images {
			position: relative;
			flex-basis: 100%;
			max-width: 100%;

			@media(max-width: 767px) {
				box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
			}

			height: 100%;

			/* padding-bottom and top for image */
			.mfp-no-margins img.mfp-img {
				padding: 0;
			}

			/* position of shadow behind the image */
			.mfp-no-margins .mfp-figure:after {
				top: 0;
				bottom: 0;
			}

			/* padding for main container */
			.mfp-no-margins .mfp-container {
				padding: 0;
			}


			.mfp-with-zoom .mfp-container,
			.mfp-with-zoom.mfp-bg {
				opacity: 0;
				-webkit-backface-visibility: hidden;
				-webkit-transition: all 0.3s ease-out;
				-moz-transition: all 0.3s ease-out;
				-o-transition: all 0.3s ease-out;
				transition: all 0.3s ease-out;
			}

			.mfp-with-zoom.mfp-ready .mfp-container {
				opacity: 1;
			}

			.mfp-with-zoom.mfp-ready.mfp-bg {
				opacity: 0.8;
			}

			.mfp-with-zoom.mfp-removing .mfp-container,
			.mfp-with-zoom.mfp-removing.mfp-bg {
				opacity: 0;
			}

			

			.top-section{
				position: absolute;
				z-index: 10;
				top: 0;
				left: 0;
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				@media(max-width: 767px) {
					position: relative;
					align-items: stretch;
					background: #69cce0;
				}
				h1 {
					background: $blue;
					display: inline-block;
					color: #fff;
					padding: 5px 20px;
					font-size: 27px;
					font-weight: 500;
					font-family: futura-pt;
					z-index: 10;
					@media(max-width: 767px) {
						display: inline-flex;
						align-items: center;
					}
					@media(max-width: 991px) {
						font-size: 22px;
						
					}
					@media(max-width: 767px) {
						font-size: 20px;
						position: relative;
						width: 100%;
						margin-bottom: 0;
						padding: 5px 15px;
					}
					
				}
				.company-logos{
					padding: 10px;
					display: flex;
					align-items: center;
					.company-logo{
						background: #fff;
							display: inline-flex;
							align-items: flex-start;
						margin-left: 10px;
						border: 4px solid #e4e4e4;
						padding: 3px;
						width: 50px;
						img{
							display: block; 
							margin: 0 auto;
							max-height: 40px;
						}

						&:first-child{
							margin-left: 0;
						}
	
					}
	
				}
			}

			

			#image-carousel {
				position: relative;
				z-index: 9;
				height: 100%;

				@media(max-width: 767px) {
					height: auto;
				}

				.owl-stage-outer {
					height: 100%;

					.owl-stage {
						height: 100%;

						.owl-item {
							height: 100%;
						}
					}
				}

				.item {
					height: 100%;
					display: block;
					background-size: cover;
					background-position: 50%;

					@media(max-width: 767px) {
						height: 250px;
					}
					@media(max-width: 575px) {
						height: 47vw;
					}


				}
			}

			.thumbnails {
				position: absolute;
				z-index: 10;
				width: 100%;
				background: rgba(253, 181, 21, 0.5);
				bottom: 0;
				left: 0;
				padding: 10px 50px;

				@media(max-width: 767px) {
					position: relative;
					background: $orange;
				}

				#thumbnail-carousel {
					position: relative;

					.owl-item {
						.item {
							border: 3px solid #e4e4e4;
							height: 80px;
							cursor: pointer;
							display: block;
							background-size: cover;
							background-position: 50%;
							margin: 0 3px;
							box-sizing: border-box;

							@media(max-width: 991px) {
								height: 60px;
							}
						}


						&:hover,
						&:focus,
						&.current {
							.item {
								border: 3px solid #fff;
							}
						}
					}



					.owl-nav {
						margin: 0;

						.owl-prev,
						.owl-next {
							background: $blue;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;

							i {
								color: #fff;
								font-size: 17px;
								line-height: 1;

								text-align: center;
							}

							&:focus,
							&:hover {
								outline: none;
							}
						}

						.owl-prev {
							position: absolute;
							left: -45px;
							width: 30px;
							height: 30px;
							top: calc(50% - 20px);
						}

						.owl-next {
							position: absolute;
							right: -45px;
							width: 30px;
							height: 30px;
							top: calc(50% - 20px);
						}
					}
				}
			}
		}

		.extra-buttons {
			.button {
				padding: 14px 20px;
				margin-top: 15px;

				@media(max-width: 991px) {
					margin-top: 30px;
				}
				@media(max-width: 575px) {
					margin-top: 15px;
					margin-left: 15px;
					margin-right: 15px;
				}
			}
		}

		.sidebar {
			.button {
				padding: 14px 20px;
				margin-top: 15px;

				
			}

			.ratings {
				padding: 20px;
				box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
				background: #fff;

				@media(max-width: 575px) {
					padding: 10px 15px;
				}

				.number_of_reviews{
					padding: 2px 10px;
					font-weight: 300;
					color: #666;
					margin-top: -5px;
					font-size: 15px;
					b{
						font-weight: 500;
						color: #333;
					}
					@media(max-width: 575px) {
						padding: 2px 0;
					}
				}

				.user-rating {
					padding: 10px;
					padding-right: 30px;

					@media(max-width: 575px) {
						padding-left: 0;
					}

					position: relative;
					color: $text;
					font-weight: 300;

					&:after {
						content: '\f105';
						font-family: FontAwesome;
						font-size: 30px;
						color: #666;
						position: absolute;
						top: 0;
						right: 0;
						height: 100%;
						width: 30px;
						display: flex;
						align-items: center;
						justify-content: center;

					}

					font-size: 16px;

					.username {
						color: $orange;
						font-weight: 500;
					}

					.hall {

						color: $blue;
						font-weight: 500;
					}


					&:hover,
					&:focus {
						background: #f9f9f9;
						text-decoration: none;
					}
				}

				.rating-row {

					padding: 5px 10px;

					@media(max-width: 575px) {
						padding: 5px 0px;
					}

					&.overall-rating {
						//border-top: 1px solid $blue;
						border-bottom: 1px solid $blue;
						padding: 10px 10px;

						@media(max-width: 575px) {
							padding: 8px 0px;
						}

						.rating-name {
							font-size: 23px;

							@media(max-width: 575px) {
								font-size: 21px;
							}
						}

						.rating-stars {
							ul {
								margin: 0;
								white-space: nowrap;

								li {
									margin: 0;

									i {
										color: #fdb415;
									}
								}
							}
						}
					}

					.rating-name {
						font-weight: 500;
						font-size: 20px;

						@media(max-width: 575px) {
							font-size: 18px;
						}
					}

					.rating-stars {
						ul {
							margin: 0;
							white-space: nowrap;

							li {
								margin: 0;

								i {
									color: #fed273;
								}
							}
						}
					}

				}
			}
		}

		#room-tabs {
			padding: 40px 0 0;

			@media(max-width: 767px) {
				padding: 30px 0;
			}

			@media(max-width: 575px) {
				padding: 20px 0;
			}

			.tabs-container {
				background: $orange;
				padding: 10px 0;

				.nav-tabs {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 0;

					a {
						padding: 5px 15px;
						margin: 0 40px;
						font-weight: 500;
						font-size: 28px;
						color: #fff;
						border: 0;
						line-height: 1;
						border-radius: 0;

						&.active {
							color: $orange;
						}

						@media(max-width: 991px) {
							margin: 2px 10px;
							
						}

						@media(max-width: 767px) {
							margin: 2px 5px;
							padding: 10px 15px;
							font-size: 22px;
						}

						@media(max-width: 575px) {
							flex-basis: 100%;
							max-width: 100%;
							text-align: center;
							margin: 2px 0;
						}
					}
				}
			}

			.tabs-content-container {
				background: #f5f5f5;
				padding: 60px 0;

				@media(max-width: 1199px) {
					padding: 50px 0;
				}

				@media(max-width: 991px) {
					padding: 40px 0;
				}

				@media(max-width: 767px) {
					padding: 30px 0;
				}

				@media(max-width: 575px) {
					padding: 20px 0;
				}

				#overview {
					font-weight: 300;
					span {
						color: #000;
						font-family: 'Open Sauce One', sans-serif;
						line-height: 1.7;
					}
					h3 {
						font-size: 28px;
						margin: 0;
						font-weight: 500;
						margin-bottom: 20px;
						padding-bottom: 10px;
						font-weight: 500;
						border-bottom: 1px solid $blue;

						@media(max-width: 991px) {
							font-size: 25px;
						}

						@media(max-width: 767px) {
							font-size: 22px;
						}
					}
				}

				#reviews {
					font-weight: 300;

					h3 {
						font-size: 28px;
						margin: 0;
						font-weight: 500;
						margin-bottom: 20px;
						color: #666666;
						margin-right: 20px;

						@media(max-width: 991px) {
							font-size: 25px;
						}

						@media(max-width: 767px) {
							font-size: 22px;
						}
					}

					.review {
						color: #666666;
						margin-bottom: 50px;

						

						ul {
							margin: 0;
							white-space: nowrap;

							li {
								margin: 0;
							}
						}

						.review-title {
							background: $blue;
							display: flex;
							align-items: stretch;
							justify-content: space-between;

							>div {
								
								.ratings-toggle{
									
									display: none;
								}
							}

							@media(max-width: 767px) {
								flex-wrap: wrap;
								>div {
									flex-basis: 100%;
									max-width: 100%;
									display: flex;
									justify-content: space-between;
									flex-direction: row-reverse;
									.ratings-toggle{
										padding: 10px 15px;
										font-weight: 500;
										letter-spacing: 1px;
										background: #ebebeb;
										text-align: right;
										display: block;
										flex-grow: 1;
										cursor: pointer;
										i{
											font-size: 20px;
										}

										&.open{
											i{
												transform: rotate(180deg);
												transform-origin: 50% 50%;
											}
										}
										&:hover, &:focus{
											background: $orange;
											color: #fff;
										}
										
									}
								}
							}

							span {
								display: block;
								padding: 10px 20px;
								font-weight: 500;
								color: #fff;
								font-size: 23px;
								display: flex;
								align-items: center;

								@media(max-width: 991px) {
									font-size: 20px;
								}

								@media(max-width: 767px) {
									font-size: 18px;
									padding: 10px 15px;
								}
							}

							.review-rating {
								background: #fff;
								padding: 15px 10px;
								flex-basis: 150px;
								max-width: 150px;
								flex-shrink: 0;
								flex-grow: 0;
								text-align: center;
								display: flex;
								align-items: center;
								justify-content: center;

								
								@media(max-width: 767px) {
									flex-basis: 120px;
									padding: 10px 15px;
									max-width: 120px;
									background: #ebebeb;
								}
							}
						}

						.review-text {
							
							padding: 20px 20px;
							font-weight: 300;
							border-left: 1px solid #ebebeb;
							border-right: 1px solid #ebebeb;
							color: #333;
							p:last-child {
								margin-bottom: 0;
							}

							.rest{
								display: none;
							}

							.read-more{
								font-weight: 500;
								cursor: pointer;
								transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
								&:hover, &:focus{
									color: $blue;
								}
							}

							@media(max-width: 767px) {
								
								padding: 10px 15px;
							}

							
						}

						

						.review-ratings {
							padding: 20px;
							
							background: #ebebeb;
							.inner {
								margin: 0 -20px;

								.single-rating {
									display: flex;
									align-items: center;
									justify-content: space-between;
									padding: 0 20px;
									margin: 5px 0;
									flex-basis: 25%;
									max-width: 25%;
									font-size: 17px;

									@media(max-width: 1199px) {
										flex-basis: 33.3333%;
										max-width: 33.3333%;
									}

									@media(max-width: 991px) {
										flex-basis: 50%;
										max-width: 50%;
									}

									@media(max-width: 767px) {
										flex-basis: 100%;
										max-width: 100%;
										
									}

									span {
										font-weight: 500;
									}

									i {
										color: #fdc13a;
									}

								}
							}
							@media(max-width: 767px) {
								
								padding: 10px 15px;
								display: none;
							}
						}

						.review-footer {
							background: #ebebeb;
							font-weight: 300;

							@media(max-width: 767px) {
								flex-wrap: wrap;
							}

							.info {
								padding: 15px 20px;

								span {
									display: inline-block;
									border-right: 1px solid #999999;
									padding: 5px 15px;
									margin-right: -4px;

									&:last-child {
										border-right: 0px solid #999999;
									}

									&:first-child {
										padding-left: 0px;
									}
								}

								@media(max-width: 767px) {
								
									padding: 10px 15px;
									font-size: 15px;

									span{
										padding: 0px 10px;
									}
								}
							}


							.recommended {
								font-weight: 500;
								display: flex;
								align-items: center;
								justify-content: space-between;

								i {
									background: #ffcc43;
									height: 100%;
									width: 63px;
									margin-left: 15px;
									color: #fff;
									font-size: 25px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								@media(max-width: 767px) {
									
									i{
										width: 40px;
										
										font-size: 20px;
									}
								}
								@media(max-width: 575px) {
									margin-left: 15px;
									flex-basis: 100%;
									max-width: 100%;
									justify-content: flex-end;
									text-align: right;
									border-top: 1px solid #f5f5f5;
									i{
										width: 35px;
										padding: 10px 0;
										margin-left:5px;
										font-size: 15px;
									}
								}
							}
						}

						.review-response {
							background: #ebebeb;
							font-weight: 300;

							

							.response-title {
								padding: 15px 20px;
								background: #aaaaaa;
								font-size: 18px;
								color: #ffffff;
								font-weight: 400;


								@media(max-width: 767px) {
								
									padding: 10px 15px;
									font-size: 15px;

								}
							}

							.response-text{
								padding: 15px 20px;
								background: #ffffff;
								line-height: 1.2;
								color: #333;
								@media(max-width: 767px) {
								
									padding: 10px 15px;

								}
							}
						}
					}
				}

				#rooms {
					font-weight: 300;
					font-size: 16px;

					h3 {
						font-size: 28px;
						margin: 0;
						font-weight: 500;
						margin-bottom: 20px;
						color: #666666;

						@media(max-width: 991px) {
							font-size: 25px;
						}

						@media(max-width: 767px) {
							font-size: 22px;
						}
					}

					table {
						border-color: #fff;

						thead {
							tr {
								th {
									background: $blue;
									color: #fff;
									border: 1px solid #fff;
									font-weight: 500;
									height: 65px;
									line-height: 1.1;
									vertical-align: middle;

									@media(max-width: 767px) {
										padding: 10px;
									}
								}
							}
						}

						tbody {
							tr {
								&:nth-of-type(odd) {
									background-color: #d6d6d6;
								}

								&:nth-of-type(even) {
									background-color: #ebebeb;
								}

								&.heading-row {
									background: #f5f5f5;

									td {
										padding: 10px 0;
										border-left: 0;
										border-right: 0;
										font-weight: 500;
										font-size: 23px;
										line-height: 1.1;

										@media(max-width: 991px) {
											font-size: 22px;
										}

										@media(max-width: 767px) {
											font-size: 20px;
											padding: 10px 0;
										}
									}
								}


								td {
									color: #666666;
									border: 1px solid #fff;
									vertical-align: middle;
									font-weight: 500;
									height: 65px;

									@media(max-width: 767px) {
										padding: 10px;
									}

									.button {
										min-width: 120px;
									}

									&.sold-out {
										color: #cc3333;
									}

									.show-more-room-info{
										
										overflow: hidden;
										display: flex;
										align-items: center;
										cursor: pointer;
										i{
											line-height: 1;
											margin-left: 5px;
											font-size: 20px;
											transition: 0.6s all cubic-bezier(0.19, 1, 0.22, 1);
										}

								
										&:hover{
											i{
												color: $orange;
											}
										
										}
									}

									.more-info{
										display: none;
										font-weight: 400;
									}
								}
							}
						}
					}

				}
			}
		}

		#hall-map {
				height: 450px !important;
				width: 100% !important;

				@media(max-width: 991px) {
					height: 350px !important;
				}

				@media(max-width: 767px) {
					height: 50vw !important;
				}
		}

 


	}

	&.contact {
		color: #666;
		padding-bottom: 70px;

		h2 {
			font-weight: bold;
			font-family: futura-pt-bold, sans-serif;
			font-size: 50px;
			color: $orange;
			margin-top: 0;
			margin-bottom: 40px;
			
			@media(max-width: 991px) {
				font-size: 28px;
				margin-bottom: 25px;
			}

			@media(max-width: 767px) {
				margin-bottom: 20px;
			}
		}

		aside {
			font-weight: 300;
			padding-right: 40px;
			@media(max-width: 991px) {
				padding-right: 0;
				margin-top: 40px;
				padding-top: 40px;
				border-top: 1px solid #e4e4e4;
			}

			strong {
				font-weight: 500;
			}

			iframe {
				max-width: 100%;
				height: 400px;
				margin-bottom: 1rem;
			}

			.button {
				padding: 10px 20px;
				display: inline-block;
			}
		}

		#contact-form {

			.error {
				color: red;
				font-style: italic;
				margin-top: 5px;
				font-size: 16px;
				font-weight: 400;
			}
			.submit-form {

				padding: 10px 15px;
				display: inline-block;

			}


			.form-group {
				label {
					font-weight: 500;
				}
			}
		}
	}

	&.rate {
		color: #666;
		padding-bottom: 70px;

		.search-bar{
			h5{
				display: none;
			}
			.search-results{
				background: #fff;
				.list{
					margin-top: 10px;
				}
				.no-results{
					display: block;
					padding: 0 10px;
				}
			}
			
		}

		aside {
			font-weight: 300;
			padding-right: 40px;

			@media(max-width: 991px) {
				font-size: 16px;
			}

			@media(max-width: 767px) {
				text-align: center;
				padding-right: 0;

			}


			img {
				max-width: 100%;
				margin-bottom: 1rem;
			}
		}

		#review-form {
			@media(max-width: 767px) {
				margin-top: 30px;
			}

			.error{
				color: red;
				font-style: italic;
				margin-top: 5px;
				font-size: 16px;
				font-weight: 400;
			}

			

			h3{
				font-weight: normal;
				font-size: 20px;
				color: #9c9c9c;
				padding-bottom: 10px;
				border-bottom: 1px solid #ccc;
			}
			.radio-inline{
				margin-left: 10px;
				input{
					margin-right: 5px;
				}
			}

			.form-check {
				padding-left: 0;
				font-weight: 500;

				.form-check-input {
					margin-left: 1rem;
				}

				margin-bottom: 2rem;
			}

			.submit-review {

				padding: 10px 20px;

			}

			.review-ratings {
				padding: 20px 0 30px;
				padding-right: 100px;

				@media(max-width: 1199px) {
					padding-right: 0;
				}

				.inner {
					margin: 0 -50px;

					@media(max-width: 991px) {
						margin: 0 -20px;
					}

					@media(max-width: 575px) {
						margin: 0 -15px;
					}

					display: flex;
					flex-wrap: wrap;
					align-items: center;

					.single-rating {
						flex-basis: 50%;
						max-width: 50%;
						padding: 0 50px;

						@media(max-width: 991px) {
							padding: 0 20px;
						}

						@media(max-width: 767px) {
							flex-basis: 100%;
							max-width: 100%;
						}

						@media(max-width: 575px) {
							padding: 0 15px;
						}

						margin: 5px 0;
						display: flex;
						align-items: center;
						justify-content: space-between;

						span {
							font-weight: 500;
						}

						ul {
							margin: 0;
							white-space: nowrap;

							li {
								margin: 0;

								i {
									color: #fdc039;
								}
							}
						}
					}
				}

			}

			.form-group {
				label {
					font-weight: 500;
				}
			}
		}
	}

	&.city-search{
		h3{
			margin-bottom: 30px;
			
			@media(max-width: 767px){
				margin-bottom: 20px;
			}
		}
		.search-row{
			padding: 0 150px;
			margin-bottom: 50px;
			@media(max-width: 991px){
				padding: 0 50px;
				margin-bottom: 40px;
			}
			@media(max-width: 767px){
				padding: 0 0;
				margin-bottom: 30px;
			}
			

			.search-results{
				top: calc(100% + 3px);
				padding: 0;
				.no-results{
					display: block;
					padding: 0 10px;
				}
			}
		}
		.search-bar {
			padding: 0;
			border: 3px solid #d9d9d9;
			background: #fff;
			position: relative;
			opacity: 0;
			z-index: 9998 !important;

			.search-icon {
				margin-right: 20px;
				margin-left: 20px;
				@media(max-width: 767px){
					margin-right: 20px;
					margin-left: 20px;
				}
				@media(max-width: 575px){
					margin-right: 15px;
					margin-left: 15px;
					img{
						width: 30px;
					}
				}
			} 

			.form-control { 
				height: 50px;
				padding: 0;
				font-size: 25px;
				font-weight: 100;
				font-weight: 300;
				margin-right: 20px;
				border: 0px;
				@media(max-width: 767px){
					font-size: 18px;
					height: 50px;
				}
			}

			::placeholder {
				color: $text;
				opacity: 1;
			}

			.button {
				font-size: 25px;
				line-height: 1;
				letter-spacing: 1px;
				@media(max-width: 767px){
					position: absolute;
					margin: 0 auto;
					margin-top: 10px;
					top: 100%;
					left: calc(50% - 80px);
					box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
				}
			}
		}
	}

	&.companies{


		.companies-section{
			.company-listing{
				margin-bottom: 20px;
				.image{
					flex-basis: 80px;
					background: #fff;
					width: 80px;
					padding: 4px;
					height: 80px;
					margin-right: 20px;
					border: 4px solid #e4e4e4;
					background-size: cover;
					background-position: 50%;
					flex-shrink: 0;
					flex-grow: 0;
				}
				h4{
					font-weight: 500;
					font-size: 18px;
					color: #666;
					transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
					span{
						display: block;
						margin-top: 5px;
						font-weight: normal;
						font-size: 14px;
						color: #aaa;

					}
				}

				&:hover, &:focus{
					h4{
						color: $orange;
					}
				}
			}
		}
	}

	&.monitor {

		padding-bottom: 0;

		.h3, h3 {
			font-size: 1.5rem;
		}

		.h4, h4 {
			font-size: 1.2rem;
		}

		.post-content {			
			.text {
				color: #000;
				font-family: 'Open Sauce One', sans-serif;
				line-height: 1.7;

				font-weight: 400;
    			font-size: 16px;
			}
			.category-title, .text h2 {
				display: block;
				font-weight: 800;
				font-size: 25px;
				margin-top: -20px;
				padding-bottom: 10px;
				margin-bottom: 30px;
				border-bottom: 1px solid $blue;
				color: $blue;



				@media(max-width: 991px) {
					font-size: 35px;
				}

				@media(max-width: 767px) {
					font-size: 30px;
				}

				@media(max-width: 575px) {
					font-size: 28px;
					margin-top: 0;
				}


			}

			.inner {
				padding: 0 80px 40px;
				color: #666;

				@media(max-width: 991px) {
					padding: 0 50px 40px;
				}

				@media(max-width: 767px) {
					padding: 0 0px 30px;
				}

				@media(max-width: 767px) {
					padding: 0 0px 20px;
				}

				h1 {
					font-size: 31px;
					font-weight: 500;
					margin-top: 0;
					margin-bottom: 5px;
					color: #666;

					@media(max-width: 991px) {
						font-size: 28px;
					}

					@media(max-width: 767px) {
						font-size: 25px;
					}

				}

				.post-category {
					position: relative;
					padding-left: 25px;
					display: block;
					font-size: 17px;
					color: #666;
					font-weight: 400;
					margin-bottom: 20px;
					text-transform: uppercase;
					margin-top: 0;
					line-height: 1.5;
					@media(max-width: 767px) {
						font-size: 14px;
						padding-left: 20px;
						margin-bottom: 15px;
					}

					&:before {
						position: absolute;
						content: '';
						height: 18px;
						width: 18px;
						border-radius: 50%;
						background: $orange;
						top: 3px;
						left: 0;

						@media(max-width: 767px) {
							height: 13px;
							width: 13px;
						}

					}
				}

				.post-image {
					margin-bottom: 2rem;
					width: 100%;

					@media(max-width: 767px) {
						margin-bottom: 1rem;
					}
				}

				strong,
				b {
					font-weight: 500;
				}

				.author {
					margin-top: 40px;

					img {
						margin-right: 20px;

						@media(max-width: 575px) {
							margin-right: 0;
							margin-bottom: 10px;
						}
					}

					.info {
						color: #666;

						.written-by {
							color: #a3a3a3;
							font-size: 17px;
							font-weight: 500;

							@media(max-width: 767px) {
								font-size: 16px;
							}

							@media(max-width: 575px) {
								font-size: 15px;
							}
						}

						h5 {
							font-size: 23px;
							margin-bottom: 5px;

							@media(max-width: 767px) {
								font-size: 21px;
							}

							@media(max-width: 575px) {
								font-size: 19px;
							}
						}

						p {
							margin-bottom: 0;
							font-size: 17px;
							font-weight: 300;

							@media(max-width: 767px) {
								font-size: 16px;
							}

							@media(max-width: 575px) {
								font-size: 15px;
							}
						}
					}
				}

				.more-button {
					padding: 10px 20px;
					margin-top: 40px;

					@media(max-width: 575px) {
						margin: 0 auto;
						margin-top: 20px;

					}
				}

			}
		}

		#blogs {
			.button {
				margin: 10px 0;
				padding: 10px 20px;
				font-size: 20px;

				@media(max-width: 767px) {
					font-size: 18px;
				}


				&.active {
					background: $orange;
				}
			}
		}

		#articles {
			background: #fff;
			padding: 40px 0 40px;
			margin-top: 40px;

			@media(max-width: 767px) {
				margin-top: 30px;
			}

			@media(max-width: 575px) {
				margin-top: 20px;
			}

			.padding {
				margin: 0 80px;
				padding: 20px 0 0;
				margin-bottom: 40px;

				@media(max-width: 991px) {
					margin: 0 40px;
				}

				@media(max-width: 767px) {
					margin: 0 0;
				}


			}

			h1 {
				font-weight: 500;
				font-size: 42px;
				margin-top: -20px;
				padding-bottom: 10px;
				margin-bottom: 20px;
				color: $orange;
				border-bottom: 1px solid $blue;

				@media(max-width: 767px) {
					font-size: 30px;
				}

				@media(max-width: 575px) {
					font-size: 28px;
				}

			}

			.post {
				margin-bottom: 50px;
				opacity: 0;
				.image {
					background-size: cover;
					background-position: 50% 50%;

					@media(max-width: 767px) {
						height: 200px;
						margin-bottom: 5px;
					}

					@media(max-width: 575px) {
						height: 45vw;
					}
				}

				h3 {
					.post-title {
						font-size: 30px;
						font-weight: 500;

						@media(max-width: 767px) {
							font-size: 25px;
						}

						@media(max-width: 575px) {
							font-size: 22px;
						}
					}

					margin-bottom: 5px;
					margin-top: 0;
				}

				.post-category {
					position: relative;
					padding-left: 25px;
					display: block;
					font-size: 14px;
					color: #666;
					font-weight: 300;
					margin-bottom: 20px;
					text-transform: uppercase;

					@media(max-width: 767px) {
						font-size: 13px;
						padding-left: 15px;
						margin-bottom: 15px;
					}


					&:before {
						position: absolute;
						content: '';
						height: 15px;
						width: 15px;
						border-radius: 50%;
						background: $orange;
						top: 1px;
						left: 0;

						@media(max-width: 767px) {
							height: 10px;
							top: 4px;
							width: 10px;
						}
					}
				}

				.post-preview {
					color: #666;
					margin-bottom: 20px;

					@media(max-width: 575px) {
						font-size: 15px;
					}

				}

				.buttons {
					.button {
						padding: 10px 20px;
						margin-right: 5px;
					}
				}
			}
		}

		#pagination {
			padding: 30px 0 70px;
			color: #666;
			font-weight: 500;


			.page-info {
				display: inline-block;
				margin: 10px auto;
				margin-right: 30px;
			}

			.pagination {

				ul {
					margin: 0;

					li {
						margin: 0;
						
					}
				}

				.first,
				.last {
					@media(max-width: 575px) {
						margin-bottom: 2px;

						a {
							padding: 8px 10px;
						}

					}

					a {
						background: #cccccc;
						color: #fff;
						padding: 8px 20px;
						font-weight: 500;
						display: block;

						&:hover,
						&:focus {
							background: #aaa;
						}
					}

				}

				.previous,
				.next {
					@media(max-width: 575px) {
						margin-bottom: 2px;

						a {
							padding: 8px 10px;
						}

					}

					a {
						background: #b9b9b9;
						color: #fff;
						font-weight: 500;
						padding: 8px 20px;
						display: block;


						&:hover,
						&:focus {
							background: #aaa;
						}
					}

				}

				.previous {
					a {
						margin-right: 5px;
					}
				}

				.next {
					a {
						margin-left: 5px;
					}

				}

				.page {

					a{
						color: $blue;
						font-weight: bold;
						font-family: futura-pt-bold, sans-serif;
						padding: 5px;
	
						&:hover,
						&:focus {
							color: darken($blue, 10%);
						}
	
						
	
					}

					
					&.current {

						
							color: $orange;
							font-weight: bold;
							padding: 5px;
							
						
					}
					

				}
			}
		}

	}


}

#nearby-halls {
	margin-bottom: 50px;

	@media(max-width: 991px) {
		margin-bottom: 40px;
	}

	@media(max-width: 767px) {
		margin-bottom: 30px;
	}

	@media(max-width: 575px) {
		margin-bottom: 20px;
	}

	h4 {
		background: $blue;
		font-weight: 500;
		font-size: 34px;
		color: #fff;
		padding: 10px 20px;
		margin-bottom: 20px;

		@media(max-width: 991px) {
			font-size: 27px;
		}

		@media(max-width: 767px) {
			padding: 10px 15px;
			font-size: 22px;
		}

	}

	.button {
		padding: 15px 20px;
		font-size: 21px;

		@media(max-width: 767px) {
			padding: 10px 20px;
			font-size: 18px;
		}

	}

	.nearby-hall {
		margin-bottom: 30px;



		.image {
			height: 240px;
			width: 100%;
			position: relative;
			background-position: 50% 50%;
			background-size: cover;

			@media(max-width: 767px) {
				height: 180px;
			}

			@media(max-width: 575px) {
				height: 45vw;
			}

			.nearby-rating {
				position: absolute;
				display: block;
				background: rgba(253, 181, 21, 0.4);
				padding: 10px 15px;
				bottom: 0;
				left: 0;

				ul {
					margin: 0;

					li {
						margin: 0;

						i {
							color: #fff;
						}
					}
				}
			}
		}

		.info {
			padding-top: 10px;

			h5 {
				font-size: 21px;
				color: $orange;
				font-weight: 500;
				margin-bottom: 3px;
				transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);

				@media(max-width: 767px) {
					font-size: 18px;
				}

			}

			.distance {
				color: $text;
				font-weight: 100;
				font-weight: 300;

				@media(max-width: 767px) {
					font-size: 14px;
				}
			}
		}

		&:hover,
		&:focus {
			text-decoration: none;

			.info {
				h5 {
					color: $blue;
				}
			}
		}


	}
}
