footer{
	background: #f1f1f1;
	padding: 0 0 50px;
	overflow: hidden;
	
	.logo{
			margin-top: 30px;
		img{
			width: 230px;
		}
	}

	.get-in-touch{
		background: $orange;
		color: #fff;
		padding: 30px 0;
		@media(max-width: 575px){
			padding: 20px 0 ;
		}
		ul{
			margin: 0;
		}

		h3{
			font-size: 35px;
			margin-bottom: 10px;
			color: #fff;
			font-weight: 500;
			@media(max-width: 991px){
				font-size: 30px;
			}
			@media(max-width: 767px){
				font-size: 25px;
			}
			
		}
	}

	.footer-row{
		margin: 0 -30px;
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		
		@media(max-width: 767px){
			margin-top: 40px;
			margin: 0 -15px;
			margin-top: 40px;
		}
		@media(max-width: 575px){
			justify-content: center;
			margin-top: 30px;
		}
		
		.column{
			flex-basis: 25%;
			max-width: 25%;
			padding: 0 30px;
			font-size: 18px;
			border-left: 1px solid #d4d4d4;
			color: #666666;
			&:first-child{
				border-left: 0px solid #d4d4d4;
			}
			@media(max-width: 991px){
				
				flex-basis: 33.3333%;
				max-width: 33.3333%;
				margin-bottom: 20px;
				&:nth-child(4){
					border-left: 0px solid #d4d4d4;
				}
			}
			@media(max-width: 767px){
				flex-basis: 100%;
				max-width: 100%;
				padding: 0 15px;
				border-left: 0px solid #d4d4d4;
				margin-bottom: 30px;
				
				&:nth-child(2),&:nth-child(3){
					flex-basis: 50%;
					max-width: 50%;
				}

			}

			
			h4{
				font-weight: 500;
				color: $blue;
				font-size: 24px;
				margin-bottom: 5px;
				
				@media(max-width: 767px){
					font-size: 22px;
				}
				@media(max-width: 575px){
					font-size: 20px;
				}
			}
			ul{
				li{
					a{
						font-size: 20px; 
						display: block;
						line-height: 1.2;
						color: #666;
						&:hover, &:focus{
							color: $orange;
							text-decoration: none;
						}
						
						@media(max-width: 575px){
							font-size: 18px;
							line-height: 1.4;
						}

						&.yellow{
							color: $orange;
						}
					}
				}
			}
		}
	}

	.social-media{
		margin: 40px 0 20px;
		@media(max-width: 767px){
			margin: 0px 0 20px;
		}
		
		ul{
			li{
				a{
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background: $blue;
					display: flex;
					@media(max-width: 575px){
						width: 30px;
						height: 30px;
					}
					align-items: center;
					justify-content: center;
					i{
						color: #fff;
						line-height: 1;
						font-size: 25px;
						@media(max-width: 575px){
							font-size: 20px;
						}
					}
					&:hover, &:focus{
						background: $orange;
						text-decoration: none;

					}
				}
			}
		}
	}

	.extra-links{
		margin: 40px 0;
		background: #e4e4e4;
		@media(max-width: 767px){
			padding: 20px 0;
		}
		ul{
			margin: 0;
		}
		nav ul li a{
			color: #666;
			display: block;
			font-size: 18px;
			margin: 10px 20px;
			&:hover, &:focus{
				color: $orange;
				text-decoration: none;
			}
			@media(max-width: 767px){
				text-align: left;
				margin: 10px 0;
			}
		}
	}

	.copyright{
		font-weight: 100;
		font-weight: 300;
		font-size: 18px; 
		color: #2f2f2f;
		@media(max-width: 575px){
			font-size: 16px;
		}
		a{
			color: #666;
			&:hover,&:focus{
				color: $orange;
			}
		}
	}


}